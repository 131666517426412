<template>
  <v-container>
    <v-main class="pt-15 pb-15">
      <loading-page v-if="loading"/>
      <div>
        <orders/>
      </div>
      <action :currentTab="page"/>
    </v-main>
  </v-container>
</template>
<script>
import loadingPage from '@/components/tools/loadingPage.vue'
import action from './components/navbar/actionBar'
export default {
  props:['app'],
  components: {
    loadingPage,
    action,
    orders: () => import ( '@/views/Factor/myOrders.vue' ),
  },
  data: () => ({
    loading: false,
    page: 'mega'
  }),
}
</script>